import { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import loading from '../../img/Loading.gif'
import { perfilamiento } from '../../services/PerfilamientoServices'
import { activarLinea } from '../../services/ActivacionLineaServices'
import { obtenerOcr } from '../../services/ObtenerOcrServices'
import {
  CreateDataActMan,
  CreateDataOcr,
  obtenerIdGestion,
} from '../../services/DbServices'

function PageFinalizacionRecuperacion() {
  const navigate = useNavigate()
  const [dui, setDui] = useState('')
  const [nombre, setNombre] = useState('')
  const [nombres, setNombres] = useState('')
  const [apellidos, setApellidos] = useState('')
  const [direccion, setDireccion] = useState('')
  const [municipio, setMunicipio] = useState('')
  const [departamento, setDepartamento] = useState('')
  const [fecha, setFecha] = useState('')
  const [activacion, setActivacion] = useState('')
  const [messageError, setMessageError] = useState('')
  const [idGestion, setIdGestion] = useState()

  obtenerOcr(sessionStorage.getItem('uid')).then((response) => {
    setNombre(response['name'] + ' ' + response['surname'])
    if (response['address'] !== null) {
      setDireccion(response['address'])
    }
    if (response['addressState'] !== null) {
      setMunicipio(response['addressState'])
    }
    if (response['addressCity'] !== null) {
      setDepartamento(response['addressCity'])
    }
    if (response['name'] !== null) {
      setNombres(response['name'])
    }
    if (response['surname'] !== null) {
      setApellidos(response['surname'])
    }
    var fechaNac = ''
    if (response['dob'] !== null) {
      fechaNac = response['dob']
    }

    try {
      const esValido = /^[\d-]+$/.test(fechaNac)
      if (esValido) {
        var fa = new Date(fechaNac)
        var dia = fa.getDate() + 1
        var mes = fa.getMonth() + 1 // Los meses en JavaScript se cuentan desde 0
        var anio = fa.getFullYear()
        setFecha(dia + '/' + mes + '/' + anio)
      } else {
        navigate('/recuperacion/error-ocr')
      }
    } catch (error) {
      navigate('/recuperacion/error-ocr')
    }

    //var duiSinGuion = ''
    if (response['documentNumber'] != null) {
      setDui(response['documentNumber'])
    } else {
      navigate('/recuperacion/error-ocr')
    }

    /*var ultimoNumero = duiSinGuion.slice(-1)
    var duiGuion = duiSinGuion.slice(0, -1) + '-' + ultimoNumero
    setDui(duiGuion)*/

    if (activacion === '') {
      const data = {
        paso: 3,
        tipo_doc: 1,
        num_doc: dui,
        nombres: nombres,
        apellidos: apellidos,
        direccion: direccion,
        fecha_nac: fechaNac,
        departamento: departamento,
        municipio: municipio,
        trx: sessionStorage.getItem('trx'),
      }
      const datosCodificado = encodeURIComponent(JSON.stringify(data))
      CreateDataOcr(datosCodificado).then((response) => {})
    }
  })

  obtenerIdGestion(sessionStorage.getItem('trx')).then((response) => {
    setIdGestion(response['id'])
  })

  const [telefono, setTelefono] = useState('')
  const location = useLocation()

  const data = location.state

  useEffect(() => {
    if (dui !== '') {
      const datos = {
        dui: dui,
        telefono: data.numero,
      }

      const info = {
        optionOperation: 2,
        customerName: nombre,
        customerNumber: data.cda,
        numberToKeep: data.numero,
        documentType: 'DUI',
        documentNumber: dui,
        address: direccion,
        dateOfBirth: fecha,
        vendorCode: '38529',
        userCode: 'ACTIVACIONES_WAGC',
        frecuentNumbers: '00000000|00000000',
      }

      perfilamiento(encodeURIComponent(JSON.stringify(datos))).then(
        (response) => {
          if (response['resultado']) {
            activarLinea(encodeURIComponent(JSON.stringify(info))).then(
              (response) => {
                if (response['status'] === 'Exitoso') {
                  const data = {
                    paso: 4,
                    telefono: sessionStorage.getItem('telefono'),
                    sales_order: response['transactionNumber'],
                    finalizado: true,
                    correo: sessionStorage.getItem('correo'),
                    trx: sessionStorage.getItem('trx'),
                  }
                  const datosCodificado = encodeURIComponent(
                    JSON.stringify(data)
                  )
                  CreateDataActMan(datosCodificado).then((response) => {})
                }
                setActivacion(response['status']) // status: fallido
                setTelefono(response['telefono'])
                setMessageError(response['error'])
              }
            )
          } else {
            //Error de perfilamiento (número no le pertenece)
            setActivacion('noPertenece')
            setTelefono(data.numero)
          }
        }
      )
    }
  }, [data, nombre, dui, fecha, direccion])

  if (activacion === '') {
    return (
      <div className='contenedor-informacion contenedor-gif'>
        <img
          src={loading}
          alt='validando-cda'
          id='loading'
          style={{ width: '100px' }}
        />
      </div>
    )
  }

  // Error de activación
  if (activacion === 'Fallido') {
    return (
      <div>
        <Header
          subT={'No podemos procesar la solicitud'}
          idGestion={`Id gestión: ${idGestion}`}
        />
        <div className='contenedor-informacion'>
          <div className='cuadro-informacion'>
            <p className='message-error-label'>{messageError}</p>
          </div>
          <div className='contenedor-botones'>
            <Link
              to={'/'}
              type='submit'
              className='btn-azul-oscuro btn-space mb-3'
            >
              Aceptar
            </Link>
          </div>
        </div>
      </div>
    )
  }

  // Error de perfilamiento
  if (activacion === 'noPertenece') {
    return (
      <div>
        <Header
          subT={'No podemos procesar la solicitud'}
          idGestion={`Id gestión: ${idGestion}`}
        />
        <div className='contenedor-informacion'>
          <div className='cuadro-informacion'>
            <p className='message-error-label'>
              {' '}
              El número Movistar {telefono} no esta registrado al nombre del
              cliente, o es una línea pospago.
            </p>
          </div>
          <div className='contenedor-botones'>
            <Link
              to={'/'}
              type='submit'
              className='btn-azul-oscuro btn-space mb-3'
            >
              Aceptar
            </Link>
          </div>
        </div>
      </div>
    )
  }

  if (activacion === 'Exitoso') {
    return (
      <div>
        <Header
          subT={'¡Recuperación Exitosa!'}
          idGestion={`Id gestión: ${idGestion}`}
        />
        <div className='contenedor-informacion'>
          <div className='cuadro-informacion'>
            <p>
              La recuperación del número <span>{telefono}</span> se realizó
              correctamente
            </p>
          </div>
          <div className='contenedor-botones'>
            <Link
              to={'/'}
              type='submit'
              className='btn-azul-oscuro  btn-space mb-2'
            >
              Aceptar
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default PageFinalizacionRecuperacion
