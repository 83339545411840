import { Formik, Field } from "formik";
import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { Form, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { baseUrl } from "../baseURL";

// fecha
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import loading from "../../img/Loading.gif";
import { IconCalendar } from "@tabler/icons-react";
import { crearNip } from "../../services/NipServices";

import { FloatLabel } from "../../js/inputs";
import { obtenerOcr } from "../../services/ObtenerOcrServices";
import {
  CreateDataCip,
  CreateDataOcr,
  CreateTrxActCom,
} from "../../services/DbServices";

// Valido todos los campos del formulario
const validacionDeFormulario = Yup.object().shape({
  numero: Yup.string()
    .matches(/^[0-9]+$/, "Solo puede contener números")
    .min(8, "El número debe tener 8 dígitos")
    .required("Número a portar es requerido"),

  dui: Yup.string()
    .min(10, "Número de DUI inválido")
    .max(10, "Número de DUI inválido")
    .matches(/^\d{8}-\d{1}/, "Ingrese número de DUI con guion")
    .required("El DUI es requerido"),

  nombres: Yup.string().required("Los nombres son requeridos"),
  apellidos: Yup.string().required("Los apellidos son requeridos"),

  correo: Yup.string()
    .email("Correo electrónico no válido")
    .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      "Correo electrónico no válido"
    )
    .nullable(),

  direccion: Yup.string().nullable(),

  cip: Yup.string()
    .required("El número CIP es requerido")
    .min(4, "Número de CIP debe contener 4 digitos")
    .max(4, "Número de CIP debe contener 4 digitos"),
  cda: Yup.string()
    .min(8, "Número de CDA debe contener 8 digitos")
    .max(8, "Número de CDA debe contener 8 digitos")
    .matches(/^\d{8}/, "Ingrese número de CDA")
    .required("Número de CDA inválido"),

  contacto: Yup.string()
    .matches(/^[0-9]+$/, "Solo puede contener números")
    .min(8, "El número debe tener 8 dígitos")
    .nullable(),

  tipo: Yup.string().required("Seleccione tipo de portación"),
  compania: Yup.string().required("Seleccione compañía actual"),
});

function errorHidden() {
  document.getElementById("CIP-No").hidden = true;
  document.getElementById("CIP-Invalido").hidden = true;
}

// inicio el script para las animaciiones de los input
function FormValidacionPortabilidad() {
  const navigate = useNavigate();
  const [nip, setNip] = useState(sessionStorage.getItem("nip"));

  const idTrx = uuidv4();
  var uid = sessionStorage.getItem("uid");

  const [dui, setDui] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [direccion, setDireccion] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [fechaNac, setFechaNac] = useState("2000-01-01");
  const [listo, setListo] = useState(false);

  obtenerOcr(uid).then((response) => {
    if (response["documentNumber"] != null) {
      setDui(response["documentNumber"]);
    } else {
      navigate("/portabilidad/error-ocr");
    }

    if (response["name"] !== null) {
      setNombre(response["name"]);
    }
    if (response["surname"] !== null) {
      setApellido(response["surname"]);
    }
    if (response["address"] !== null) {
      setDireccion(response["address"]);
    }
    if (response["addressState"] !== null) {
      setMunicipio(response["addressState"]);
    }
    if (response["addressCity"] !== null) {
      setDepartamento(response["addressCity"]);
    }

    if (response["dob"] !== null) {
      setFechaNac(response["dob"]);
    } else {
      setFechaNac("");
    }

    setListo(true);

    sessionStorage.setItem("nombre", nombre);
    sessionStorage.setItem("apellido", apellido);
    sessionStorage.setItem("dui", dui);
    sessionStorage.setItem("direccion", direccion);
    sessionStorage.setItem("municipio", municipio);
    sessionStorage.setItem("departamento", departamento);

    if (listo) {
      const data = {
        paso: 4,
        tipo_doc: 1,
        num_doc: dui,
        nombres: nombre,
        apellidos: apellido,
        direccion: direccion,
        fecha_nac: fechaNac,
        departamento: departamento,
        municipio: municipio,
        trx: sessionStorage.getItem("trx"),
      };
      const datosCodificado = encodeURIComponent(JSON.stringify(data));
      CreateDataOcr(datosCodificado).then((response) => {});
    }
  });

  useEffect(() => {
    if (listo && nip === "") {
      console.log("Creacion de nip")
      crearNip(sessionStorage.getItem("telefono")).then((response) => {
        setNip(response["idNip"]);
        sessionStorage.setItem("nip", response['idNip'])
        console.log("Solicitud de nip creada")
      });
    }

    FloatLabel.init();
    try {
      const esValido = /^[\d-]+$/.test(fechaNac);
      if (esValido) {
        sessionStorage.setItem("fechaNac", fechaNac);
        const f = new Date(fechaNac);
        f.setDate(f.getDate() + 1);
        setStartDate(f);
      } else {
        navigate("/portabilidad/error-ocr");
      }
    } catch (error) {
      navigate("/portabilidad/error-ocr");
    }
  }, [fechaNac, navigate, listo, nip]);
  console.log(nip);

  function capturarDatos() {
    const data = {
      cda: sessionStorage.getItem("cda"),
      tipo: 2,
      trx: idTrx,
      codVendedor: sessionStorage.getItem("codVendedor"),
      paso: 1,
    };
    sessionStorage.setItem("trx", idTrx);
    const datosCodificado = encodeURIComponent(JSON.stringify(data));
    CreateTrxActCom(datosCodificado).then((response) => {
      window.location.replace(
        baseUrl['uri'] + "?idTrx=" +
          idTrx +
          "&tipo=2&idSolic=" +
          response["id"] +
          "&cda=" +
          response["cda"] +
          "&seller=" +
          response["codVendedor"]
      );
    });
  }

  const [startDate, setStartDate] = useState(new Date());
  const datePickerRef = useRef(null);

  const telefono = sessionStorage.getItem("telefono");
  const correo = sessionStorage.getItem("correo");

  if (listo) {
    return (
      <div className="container h-100">
        <div className="col-md-12">
          {/* props : css, type, mLength */}
          <Formik
            // inicializando los valores de los input
            initialValues={{
              apellidos: apellido,
              cip: "",
              cda: sessionStorage.getItem("cda"),
              contacto: "",
              compania: sessionStorage.getItem("compania"),
              correo: correo,
              direccion: direccion,
              dui: dui,
              numero: telefono,
              nombres: nombre,
              tipo: "Prepago a Prepago",
            }}
            validationSchema={validacionDeFormulario}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
              document.getElementById("btn-continuar").hidden = true;
              document.getElementById("btn-tomarNueva").hidden = true;
              document.getElementById("loading").hidden = false;
              sessionStorage.setItem("cip", values.cip);
              sessionStorage.setItem("telContacto", values.contacto);

              const data = {
                cip: values.cip,
                contacto: values.contacto,
                trx: sessionStorage.getItem("trx"),
                paso: 5,
              };
              const datosCodificado = encodeURIComponent(JSON.stringify(data));
              CreateDataCip(datosCodificado).then((response) => {
                navigate("/portabilidad/firma");
              });

              // validarNip(datos).then((response) => {
              //   if (response["status"] === 200) {
              //     document.getElementById("btn-continuar").hidden = false;
              //     document.getElementById("btn-tomarNueva").hidden = false;
              //     document.getElementById("loading").hidden = true;
              //     sessionStorage.setItem('cip', values.cip)
              //     sessionStorage.setItem('telContacto', values.contacto)
              //     setTimeout(() => {
              //       window.scrollTo({
              //         top: 0,
              //         left: 0,
              //         behavior: "smooth",
              //       });
              //     }, 0);
              //     navigate("/portabilidad/firma");
              //   } else if (response["status"] === 401) {
              //     document.getElementById("btn-continuar").hidden = false;
              //     document.getElementById("btn-tomarNueva").hidden = false;
              //     document.getElementById("loading").hidden = true;
              //     document.getElementById("CIP-No").hidden = false;
              //   } else {
              //     document.getElementById("btn-continuar").hidden = false;
              //     document.getElementById("btn-tomarNueva").hidden = false;
              //     document.getElementById("loading").hidden = true;
              //     document.getElementById("CIP-Invalido").hidden = false;
              //   }
              // });
            }}
          >
            {({ handleChange, handleSubmit, errors }) => (
              <Form
                className="d-flex justify-content-center align-items-center flex-column"
                onSubmit={handleSubmit}
              >
                {/* Numero a portar */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className="float-container float-container-form">
                    <label htmlFor={"numero"}>
                      Número de teléfono a portar
                    </label>
                    <Field
                      type={"text"}
                      id={"numero"}
                      name={"numero"}
                      data-placeholder=""
                      maxLength={8}
                      autoComplete="off"
                      onChange={handleChange}
                      readOnly
                      style={{ backgroundColor: "#e6b8f126" }}
                    />
                    {errors.numero ? (
                      <div className="message-error">{errors.numero}</div>
                    ) : null}
                  </div>
                </Col>

                {/* Dui */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className="float-container float-container-form">
                    <label htmlFor={"dui"}>Número de DUI</label>
                    <Field
                      type={"text"}
                      id={"dui"}
                      name={"dui"}
                      data-placeholder=""
                      autoComplete="off"
                      readOnly
                      style={{ backgroundColor: "#e6b8f126" }}
                    />
                    {errors.dui ? (
                      <div className="message-error">{errors.dui}</div>
                    ) : null}
                  </div>
                </Col>

                {/* Nombres */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className="float-container float-container-form">
                    <label htmlFor={"nombres"}>Nombres</label>
                    <Field
                      type={"text"}
                      id={"nombres"}
                      name={"nombres"}
                      data-placeholder=""
                      autoComplete="off"
                      readOnly
                      style={{ backgroundColor: "#e6b8f126" }}
                    />
                    {errors.nombres ? (
                      <div className="message-error">{errors.nombres}</div>
                    ) : null}
                  </div>
                </Col>

                {/* Apellidos */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className="float-container float-container-form">
                    <label htmlFor={"apellidos"}>Apellidos</label>
                    <Field
                      type={"text"}
                      id={"apellidos"}
                      name={"apellidos"}
                      data-placeholder=""
                      autoComplete="off"
                      readOnly
                      style={{ backgroundColor: "#e6b8f126" }}
                    />
                    {errors.apellidos ? (
                      <div className="message-error">{errors.apellidos}</div>
                    ) : null}
                  </div>
                </Col>

                {/* Dirección */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className="float-container float-container-form">
                    <label htmlFor={"direccion"}>Dirección</label>
                    <Field
                      type={"text"}
                      id={"direccion"}
                      name={"direccion"}
                      data-placeholder=""
                      autoComplete="off"
                      readOnly={direccion ? true : false}
                      style={{ backgroundColor: "#e6b8f126" }}
                    />
                    {errors.direccion ? (
                      <div className="message-error">{errors.direccion}</div>
                    ) : null}
                  </div>
                </Col>

                {/* fecha */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className="row form-group">
                    <div className="float-container float-container-form active">
                      <div className="input-group date active" id="datepicker">
                        <label htmlFor={"fecha"}>{"Fecha de nacimiento"}</label>
                        <DatePicker
                          className="form-control"
                          id={"fecha"}
                          name={"fecha"}
                          selected={startDate}
                          dateFormat="dd/MM/yyyy"
                          ref={datePickerRef}
                          showMonthDropdown
                          showYearDropdown
                          readOnly
                        />
                        <span
                          className="input-group-append datepicker-icon"
                          onClick={() => datePickerRef.current.setOpen(true)}
                        >
                          <span className="input-group-text bg-white d-block">
                            <IconCalendar color="#50535a" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input type="hidden" id="fecharesult" value="" />
                  </div>
                </Col>

                {/* Correo */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className="float-container float-container-form">
                    <label htmlFor={"correo"}>Correo electrónico</label>
                    <Field
                      type={"text"}
                      id={"correo"}
                      name={"correo"}
                      data-placeholder=""
                      autoComplete="off"
                    />
                    {errors.correo ? (
                      <div className="message-error">{errors.correo}</div>
                    ) : null}
                  </div>
                </Col>

                {/* Numero CDA */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className="float-container float-container-form">
                    <label htmlFor={"cda"}>Número CDA del chip Movistar</label>
                    <Field
                      type={"text"}
                      id={"cda"}
                      name={"cda"}
                      data-placeholder=""
                      maxLength={10}
                      autoComplete="off"
                      readOnly
                      style={{ backgroundColor: "#e6b8f126" }}
                    />
                    {errors.cda ? (
                      <div className="message-error">{errors.cda}</div>
                    ) : null}
                  </div>
                </Col>

                {/* Número CIP */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className="float-container float-container-form">
                    <label htmlFor={"cip"}>Número CIP</label>
                    <Field
                      type={"text"}
                      id={"cip"}
                      name={"cip"}
                      data-placeholder=""
                      autoComplete="off"
                    />
                    {errors.cip ? (
                      <div className="message-error">{errors.cip}</div>
                    ) : null}
                    <div className="message-error" id="CIP-No" hidden>
                      CIP no está asociado al número
                    </div>
                    <div className="message-error" id="CIP-Invalido" hidden>
                      CIP inválido
                    </div>
                  </div>
                </Col>

                {/* Numero de contacto */}
                <Col xs={12} sm={12} md={9} lg={5} xl={4}>
                  <div className="float-container float-container-form">
                    <label htmlFor={"contacto"}>Número de contacto</label>
                    <Field
                      type={"text"}
                      id={"contacto"}
                      name={"contacto"}
                      data-placeholder=""
                      maxLength={8}
                      autoComplete="off"
                      onChange={handleChange}
                    />
                    {errors.contacto ? (
                      <div className="message-error">{errors.contacto}</div>
                    ) : null}
                  </div>
                </Col>

                <br />
                <button
                  type="submit"
                  className="btn_verde mb-3"
                  id="btn-continuar"
                  onClick={errorHidden}
                >
                  Continuar
                </button>
                <button
                  type="button"
                  id="btn-tomarNueva"
                  className="btn-azul-oscuro btn-space mb-5"
                  onClick={capturarDatos}
                >
                  Tomar fotos nuevamente
                </button>
                <img src={loading} alt="validando-cda" id="loading" hidden />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default FormValidacionPortabilidad;
