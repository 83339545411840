import { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import FormValidacionPortabilidad from "../../components/portabilidad/FormValidacionPortabilidad";
import { obtenerIdGestion } from "../../services/DbServices";
import loading from "../../img/Loading.gif";
import { obtenerOcr } from "../../services/ObtenerOcrServices";
import { validarMaxLineas } from "../../services/ValidarMaxLineas";

const PageValidacionPortabilidad = () => {
  const [validarCliente, setValidarCliente] = useState("");
  const navigate = useNavigate();
  const [idGestion, setIdGestion] = useState();
  
  useEffect(() => {
    var uid = sessionStorage.getItem("uid");
    var dui = "";
    obtenerOcr(uid).then((response) => {
      if (response["documentNumber"] != null) {
        dui = response["documentNumber"];
      } else {
        navigate("/activar/error-ocr");
      }

      obtenerIdGestion(sessionStorage.getItem('trx')).then((response) => {
        setIdGestion(response['id'])
      })

      validarMaxLineas(dui)
        .then((response) => {
          setValidarCliente(response["resultado"]);
        })
        .catch((error) => {
          console.log(error);
          navigate("/activar/error-ocr");
        });
    });
  }, [navigate]);

  if (validarCliente === "") {
    return (
      <div className="contenedor-informacion contenedor-gif">
        <img
          src={loading}
          alt="validando-cda"
          id="loading"
          style={{ width: "100px" }}
        />
      </div>
    );
  }

  if (validarCliente !== "OK" && validarCliente !== "") {
    return (
      <div>
        <div className="contenedor-informacion">
          <div className="cuadro-informacion" style={{ marginTop: "6rem" }}>
            <p>Cliente alcanzó límite de líneas</p>
          </div>
          <div className="contenedor-botones">
            <Link
              to={"/"}
              type="submit"
              className="btn-azul-oscuro btn-space mb-3"
            >
              Aceptar
            </Link>
          </div>
        </div>
      </div>
    );
  }

  if (validarCliente === "Fail") {
    return (
      <div>
        <div className="contenedor-informacion">
          <div className="cuadro-informacion" style={{ marginTop: "6rem" }}>
            <p>Error de conexión, actualiza la página</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header
        subT={"Verificación de Datos"}
        idGestion={`Id gestión: ${idGestion}`}
      />
      <FormValidacionPortabilidad />
    </div>
  );
};

export default PageValidacionPortabilidad;
