import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { Link } from 'react-router-dom'
import { activarLinea } from '../../services/ActivacionLineaServices'
import loading from '../../img/Loading.gif'
import { obtenerOcr } from '../../services/ObtenerOcrServices'
import { CreateDataActMan, obtenerIdGestion } from '../../services/DbServices'
import { envioDatosBot } from '../../services/EnvioDatosBot'

function PageFinalizacionActivacion() {
  const [dui, setDui] = useState('')
  const [nombre, setNombre] = useState('')
  const [fecha, setFecha] = useState('')
  const [direccion, setDireccion] = useState('')
  const [messageError, setMessageError] = useState('')
  const [idGestion, setIdGestion] = useState()

  //Consulta a OCR
  obtenerOcr(sessionStorage.getItem('uid')).then((response) => {
    setNombre(response['name'] + ' ' + response['surname'])
    if (response['address'] !== null) {
      setDireccion(response['address'])
    }
    var fa = ''
    if (response['dob'] !== null) {
      fa = response['dob']
    }
    const f = new Date(fa)
    var dia = f.getDate() + 1
    var mes = f.getMonth() + 1 // Los meses en JavaScript se cuentan desde 0
    var anio = f.getFullYear()
    setFecha(dia + '/' + mes + '/' + anio)

    /*const duiSinGuion = response['documentNumber']
    var ultimoNumero = duiSinGuion.slice(-1)
    var duiGuion = duiSinGuion.slice(0, -1) + '-' + ultimoNumero*/

    setDui(response['documentNumber'])
  })

  // Id de gestión
  obtenerIdGestion(sessionStorage.getItem('trx')).then((response) => {
    setIdGestion(response['id'])
  })

  useEffect(() => {
    //Datos quemados temporalmente
    if (fecha !== '') {
      const datos = {
        optionOperation: 1,
        customerName: nombre,
        customerNumber: sessionStorage.getItem('cda'),
        numberToKeep: 0,
        documentType: 'DUI',
        documentNumber: dui,
        address: direccion,
        dateOfBirth: fecha,
        vendorCode: '38529',
        userCode: 'ACTIVACIONES_WAGC',
        frecuentNumbers: '00000000|00000000',
      }
      activarLinea(encodeURIComponent(JSON.stringify(datos))).then(
        (response) => {
          const data = {
            paso: 4,
            telefono: response['description'],
            sales_order: response['transactionNumber'],
            finalizado: true,
            correo: sessionStorage.getItem('correo'),
            trx: sessionStorage.getItem('trx'),
            tipo: 1,
            cda: sessionStorage.getItem('cda'),
            codVendedor: sessionStorage.getItem('codVendedor'),
            numero_portar: '-',
            request_id_spn: '-',
          }
          const datosCodificado = encodeURIComponent(JSON.stringify(data))
          CreateDataActMan(datosCodificado).then((response) => {
            envioDatosBot(data).then((response) => {
              if (response.status === 1) {
                console.log(response.mensaje)
              }
            })
          })
          setActivacion(response['status'])
          setTelefono(response['description'])
          setMessageError(response['error'])
        }
      )
    }
  }, [fecha])

  const [activacion, setActivacion] = useState('')
  const [telefono, setTelefono] = useState('')

  if (activacion === '') {
    return (
      <div className='contenedor-informacion contenedor-gif'>
        <img
          src={loading}
          alt='validando-cda'
          id='loading'
          style={{ width: '100px' }}
        />
      </div>
    )
  }

  if (activacion === 'Fallido') {
    return (
      <div>
        <Header
          subT={'No podemos procesar la solicitud'}
          idGestion={`Id gestión: ${idGestion}`}
        />
        <div className='contenedor-informacion'>
          <div className='cuadro-informacion'>
            <p className='message-error-label'>{messageError}</p>
          </div>
          <div className='contenedor-botones'>
            <Link
              to={'/'}
              type='submit'
              className='btn-azul-oscuro btn-space mb-3'
            >
              Aceptar
            </Link>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Header
        subT={'¡Activación exitosa!'}
        idGestion={`Id gestión: ${idGestion}`}
      />
      <div className='contenedor-informacion'>
        <div className='cuadro-informacion'>
          <p>
            El número CDA {sessionStorage.getItem('cda')} fue activado
            correctamente con el número de teléfono <span>{telefono}</span>
          </p>
        </div>
        <div className='contenedor-info-extra-mayor'>
          <p>Te damos la bienvenida a Movistar, esperá al menos 1</p>
          <p>minuto para completar el proceso y poder usar tu línea.</p>
        </div>
        <div className='contenedor-info-extra-menor'>
          <p>
            Te damos la bienvenida a Movistar, espera al menos 1 minuto para
            completar el proceso y poder usar tu línea.
          </p>
        </div>
        <div className='contenedor-botones'>
          <Link
            to={'/'}
            type='submit'
            className='btn-azul-oscuro btn-space mb-3'
          >
            Aceptar
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PageFinalizacionActivacion
